import { Temporal, toTemporalInstant } from '@js-temporal/polyfill';
import keyBy from 'lodash/keyBy.js';

import {
  formatDate,
  formatDateYMD,
  formatDateYMDHM,
  formatInterval,
} from '@prairielearn/formatter';
import { HtmlSafeString, html } from '@prairielearn/html';

import { HOUR_IN_MILLISECONDS, MINUTE_IN_MILLISECONDS, SECOND_IN_MILLISECONDS } from './date.js';

/**
 * Truncates a string to a maximum length, adding an ellipsis if the string is
 * truncated.
 */
export function truncate(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }
  const end = Math.max(0, maxLength - 3);
  return str.slice(0, end) + '...';
}

/**
 * Format a number with a unit, using the singular or plural form as appropriate.
 *
 * @param value The number to format.
 * @param singular The singular form of the unit.
 * @param plural The plural form of the unit.
 */
export function pluralize(value: number, singular: string, plural: string): string {
  if (value == 1) {
    return `${value} ${singular}`;
  } else {
    return `${value} ${plural}`;
  }
}

/**
 * Converts a fraction (numerator/denominator) to a percentage, safely dealing
 * with division by zero and out-of-range values.
 *
 * @param numerator The numerator of the fraction.
 * @param denominator The denominator of the fraction.
 * @returns The percentage value of the fraction, or 0 if the denominator is 0.
 */
export function safePercentage(numerator: number, denominator: number): number {
  if (denominator <= 0) {
    return 0;
  }
  return Math.max(0, Math.min(100, (numerator / denominator) * 100));
}

/**
 * Converts a fraction (numerator/denominator) to a percentage, rounded to an
 * integer value. Also safely handles division by zero and out-of-range values.
 *
 * @param numerator The numerator of the fraction.
 * @param denominator The denominator of the fraction.
 * @returns The percentage value of the fraction, rounded to an integer.
 */
export function roundedPercentage(numerator: number, denominator: number): number {
  if (denominator <= 0) {
    return 0;
  }
  return Math.max(0, Math.min(100, Math.round((numerator / denominator) * 100)));
}

/**
 * Compute the complementary text color for a given background color. The text
 * color will be either #000000 or #ffffff, whichever has the highest contrast
 * with the background color.
 *
 * @param backgroundColor The background color to compute the complementary text color for (e.g., '#ff0000').
 * @returns The complementary text color (e.g., '#ffffff').
 */
export function complementaryTextColor(backgroundColor: string): string {
  const r = parseInt(backgroundColor.slice(1, 3), 16);
  const g = parseInt(backgroundColor.slice(3, 5), 16);
  const b = parseInt(backgroundColor.slice(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? '#000000' : '#ffffff';
}

/**
 * Format an interval (in milliseconds) to a human-readable string like 'Until 6
 * minutes before the session start time'.
 *
 * @param interval Time interval in milliseconds relative to `reference` (positive intervals are after `reference`).
 * @param prefix The prefix to use, must be 'Until' or 'From' (or lowercase versions of these).
 * @param reference The reference time, for example 'session start time'.
 * @returns Human-readable string representing the interval.
 */
export function formatIntervalRelative(
  interval: number,
  prefix: 'Until' | 'until' | 'From' | 'from',
  reference: string,
): string {
  if (interval > 0) {
    return `${prefix} ${formatInterval(interval)} after ${reference}`;
  } else if (interval < 0) {
    return `${prefix} ${formatInterval(-interval)} before ${reference}`;
  } else if (interval == 0) {
    return `${prefix} ${reference}`;
  } else {
    return `Invalid interval: ${interval}`;
  }
}

/**
 * Format an interval (in milliseconds) to a human-readable string with the number of minutes, like '7 minutes' or '1 minute'.
 *
 * @param interval Time interval in milliseconds.
 * @returns Human-readable string representing the interval in minutes.
 */
export function formatIntervalMinutes(interval: number): string {
  const sign = interval < 0 ? '-' : '';
  const minutes = Math.ceil(Math.abs(interval / MINUTE_IN_MILLISECONDS));
  if (minutes == 1) {
    return `${sign}1 minute`;
  } else {
    return `${sign}${minutes} minutes`;
  }
}

/**
 * Format an interval (in milliseconds) to a human-readable string like HH:MM or +HH:MM.
 *
 * @param interval Time interval in milliseconds.
 * @param options.signed Whether to include the sign in the output.
 * @returns Human-readable string representing the interval in minutes.
 */
export function formatIntervalHM(
  interval: number,
  { signed = false }: { signed?: boolean } = { signed: false },
): string {
  const sign = interval < 0 ? '-' : interval > 0 ? (signed ? '+' : '') : '';
  const hours = Math.floor(Math.abs(interval) / HOUR_IN_MILLISECONDS);
  const mins = Math.floor(Math.abs(interval) / MINUTE_IN_MILLISECONDS) % 60;
  return `${sign}${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
}

/**
 * Converts an interval (in milliseconds) to a floating-point minutes (a Number).
 *
 * @param interval The interval time in milliseconds.
 * @returns The number of minutes in the interval.
 */
export function intervalToMinutes(interval: number): number {
  return interval / MINUTE_IN_MILLISECONDS;
}

/**
 * Converts an interval (in milliseconds) to an integer minutes (a Number).
 *
 * @param interval Time interval in milliseconds.
 * @returns The number of minutes in the interval (rounded to the nearest minute).
 */
export function intervalToRoundedMinutes(interval: number): number {
  return Math.round(intervalToMinutes(interval));
}

/**
 * Converts an interval (in milliseconds) to an integer seconds (a Number).
 *
 * @param interval Time interval in milliseconds.
 * @returns The number of seconds in the interval (rounded to the nearest second).
 */
export function intervalToRoundedSeconds(interval: number): number {
  return Math.round(interval / SECOND_IN_MILLISECONDS);
}

/**
 * Makes an interval (in milliseconds).
 *
 * @param param.days The number of days in the interval.
 * @param param.hours The number of hours in the interval.
 * @param param.minutes The number of minutes in the interval.
 * @param param.seconds The number of seconds in the interval.
 */
export function makeInterval({
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
}: {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}): number {
  return (((days * 24 + hours) * 60 + minutes) * 60 + seconds) * 1000;
}

/**
 * Format an optional date to a string or return "unknown".
 *
 * @param date The date to format.
 * @param timeZone The time zone to use for formatting.
 * @param param.includeTz Whether to include the time zone in the output (default true).
 * @returns Human-readable string representing the date or "unknown".
 */
export function formatOptionalDate(
  date: Date | null,
  timeZone: string,
  { includeTz = true }: { includeTz?: boolean } = {},
): string {
  if (date === null) {
    return 'unknown';
  } else {
    return formatDate(date, timeZone, { includeTz });
  }
}

/**
 * Format a date to a human-readable string like '14:27:00 (CDT)'.
 *
 * @param date The date to format.
 * @param timeZone The time zone to use for formatting.
 * @param param2.includeTz Whether to include the time zone in the output (default true).
 * @returns Human-readable string representing the date.
 */
export function formatDateHMS(
  date: Date,
  timeZone: string,
  { includeTz = true }: { includeTz?: boolean } = {},
): string {
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  };
  const parts = keyBy(new Intl.DateTimeFormat('en-US', options).formatToParts(date), (x) => x.type);
  let dateFormatted = `${parts.hour.value}:${parts.minute.value}:${parts.second.value}`;
  if (includeTz) {
    dateFormatted = `${dateFormatted} (${parts.timeZoneName.value})`;
  }
  return dateFormatted;
}

/**
 * Format a date to a human-readable string like '18:23' or 'May 2, 07:12',
 * where the precision is determined by the range.
 *
 * @param date The date to format.
 * @param rangeStart The start of the range.
 * @param rangeEnd The end of the range.
 * @param timeZone The time zone to use for formatting.
 * @returns Human-readable string representing the date.
 */
export function formatDateWithinRange(
  date: Date,
  rangeStart: Date,
  rangeEnd: Date,
  timeZone: string,
): string {
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    hourCycle: 'h23',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  };
  const dateParts = keyBy(
    new Intl.DateTimeFormat('en-US', options).formatToParts(date),
    (x) => x.type,
  );
  const startParts = keyBy(
    new Intl.DateTimeFormat('en-US', options).formatToParts(rangeStart),
    (x) => x.type,
  );
  const endParts = keyBy(
    new Intl.DateTimeFormat('en-US', options).formatToParts(rangeEnd),
    (x) => x.type,
  );

  // format the date (not time) parts
  const dateYMD = `${dateParts.year.value}-${dateParts.month.value}-${dateParts.day.value}`;
  const startYMD = `${startParts.year.value}-${startParts.month.value}-${startParts.day.value}`;
  const endYMD = `${endParts.year.value}-${endParts.month.value}-${endParts.day.value}`;

  if (dateYMD === startYMD && dateYMD === endYMD) {
    // only show the time if the date is the same for all three
    return `${dateParts.hour.value}:${dateParts.minute.value}`;
  }

  // format the year, but not the month or day
  const dateY = `${dateParts.year.value}`;
  const startY = `${startParts.year.value}`;
  const endY = `${endParts.year.value}`;

  // if the year is the same for all three, show the month, day, and time
  if (dateY === startY && dateY === endY) {
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const dateParts = keyBy(
      new Intl.DateTimeFormat('en-US', options).formatToParts(date),
      (x) => x.type,
    );
    return `${dateParts.month.value} ${dateParts.day.value}, ${dateParts.hour.value}:${dateParts.minute.value}`;
  }

  // fall back to the full date
  return `${dateParts.year.value}-${dateParts.month.value}-${dateParts.day.value} ${dateParts.hour.value}:${dateParts.minute.value}`;
}

/**
 * Return the day of the week as a three-letter abbreviation.
 *
 * @param dayOfWeekIndex The day of the week, where 1 is Monday and 7 is Sunday (ISO standard day-of-week numbering).
 * @returns The day of the week as a three-letter abbreviation.
 */
export function getDayOfWeekAbbreviation(dayOfWeekIndex: number): string {
  if (dayOfWeekIndex < 1 || dayOfWeekIndex > 7) {
    throw new Error(`Invalid day of week index: ${dayOfWeekIndex}`);
  }
  return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][dayOfWeekIndex - 1];
}

/**
 * Formats a PlainDateTime to a human-readable string like '2020-03-27 14:27'.
 *
 * @param plainDateTime The PlainDateTime to format.
 * @returns Human-readable string representing the date.
 */
export function formatPlainDateTimeYMDHM(plainDateTime: Temporal.PlainDateTime): string {
  return `${plainDateTime.toPlainDate().toString()} ${plainDateTime
    .toPlainTime()
    .toString({ smallestUnit: 'minute' })}`;
}

/**
 * Format a Date to date and time strings in the given time zone. The date is
 * formatted like
 * - 'today'
 * - 'Mon, Mar 20' (if within 180 days of the base date)
 * - 'Wed, Jan 1, 2020'
 *
 * The time format leaves off zero minutes and seconds, and uses 12-hour time,
 * giving strings like
 * - '3pm'
 * - '3:34pm'
 * - '3:34:17pm'
 *
 * @param date The date to format.
 * @param timezone The time zone to use for formatting.
 * @param baseDate The base date to use for comparison.
 */
function formatDateFriendlyParts(
  date: Date,
  timezone: string,
  baseDate: Date,
): { dateFormatted: string; timeFormatted: string; timezoneFormatted: string } {
  // compute the number of days from the base date (0 = today, 1 = tomorrow, etc.)

  const baseZonedDateTime = toTemporalInstant
    .call(baseDate)
    .toZonedDateTimeISO(Temporal.TimeZone.from(timezone));
  const zonedDateTime = toTemporalInstant
    .call(date)
    .toZonedDateTimeISO(Temporal.TimeZone.from(timezone));

  const basePlainDate = baseZonedDateTime.toPlainDate();
  const plainDate = zonedDateTime.toPlainDate();

  const daysOffset = plainDate.since(basePlainDate, { largestUnit: 'day' }).days;

  // format the parts of the date and time

  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hourCycle: 'h12',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  };
  const parts = keyBy(new Intl.DateTimeFormat('en-US', options).formatToParts(date), (x) => x.type);

  // format the date string

  let dateFormatted = '';
  if (daysOffset === 0) {
    dateFormatted = 'today';
  } else if (daysOffset === 1) {
    dateFormatted = 'tomorrow';
  } else if (daysOffset === -1) {
    dateFormatted = 'yesterday';
  } else if (Math.abs(daysOffset) <= 180) {
    // non-breaking-space (\u00a0) is used between the month and day
    dateFormatted = `${parts.weekday.value}, ${parts.month.value}\u00a0${parts.day.value}`;
  } else {
    dateFormatted = `${parts.weekday.value}, ${parts.month.value}\u00a0${parts.day.value}, ${parts.year.value}`;
  }

  // format the time string

  let timeFormatted = '';
  if (parts.minute.value == '00' && parts.second.value == '00') {
    timeFormatted = `${parts.hour.value}`;
  } else if (parts.second.value == '00') {
    timeFormatted = `${parts.hour.value}:${parts.minute.value}`;
  } else {
    timeFormatted = `${parts.hour.value}:${parts.minute.value}:${parts.second.value}`;
  }
  // add the am/pm part
  timeFormatted = `${timeFormatted}${parts.dayPeriod.value.toLowerCase()}`;

  // format the timezone

  const timezoneFormatted = `(${parts.timeZoneName.value})`;

  return {
    dateFormatted,
    timeFormatted,
    timezoneFormatted,
  };
}

/**
 * Format a date to a string like:
 * - 'today, 3pm'
 * - 'tomorrow, 10:30am'
 * - 'yesterday, 11:45pm'
 * - 'Mon, Mar 20, 8:15am' (if within 180 days of the base date)
 * - 'Wed, Jan 1, 2020, 12pm'
 * - `today, 3pm (CDT)` (if `includeTz` is true)
 * - `3pm today` (if `timeFirst` is true)
 * - 'today' (if `dateOnly` is true)
 *
 * If using this within a sentence like `... at ${formatDateFriendlyString()}`,
 * use `timeFirst: true` to improve readability.
 *
 * @param date The date to format.
 * @param timezone The time zone to use for formatting.
 * @param param.baseDate The base date to use for comparison (default is the current date).
 * @param param.includeTz Whether to include the time zone in the output (default true).
 * @param param.timeFirst If true, the time is shown before the date (default false).
 * @param param.dateOnly If true, only the date is shown (default false).
 * @param param.timeOnly If true, only the time is shown (default false).
 * @returns Human-readable string representing the date and time.
 */
export function formatDateFriendlyString(
  date: Date,
  timezone: string,
  {
    baseDate = new Date(),
    includeTz = true,
    timeFirst = false,
    dateOnly = false,
    timeOnly = false,
  }: {
    baseDate?: Date;
    includeTz?: boolean;
    timeFirst?: boolean;
    dateOnly?: boolean;
    timeOnly?: boolean;
  } = {},
): string {
  const { dateFormatted, timeFormatted, timezoneFormatted } = formatDateFriendlyParts(
    date,
    timezone,
    baseDate,
  );

  let dateTimeFormatted = '';
  if (dateOnly) {
    dateTimeFormatted = dateFormatted;
  } else if (timeOnly) {
    dateTimeFormatted = timeFormatted;
  } else {
    if (timeFirst) {
      dateTimeFormatted = `${timeFormatted} ${dateFormatted}`;
    } else {
      dateTimeFormatted = `${dateFormatted}, ${timeFormatted}`;
    }
  }
  if (includeTz) {
    dateTimeFormatted = `${dateTimeFormatted} ${timezoneFormatted}`;
  }
  return dateTimeFormatted;
}

/**
 * Format a date to HTML, displaying the date with `formatDateFriendlyString()`
 * and with a tooltip showing the exact date and time.
 *
 * @param date The date to format.
 * @param timezone The time zone to use for formatting.
 * @param options Additional options for formatting the displayed date, taken from `formatDateFriendlyString()`.
 * @param options.liveUpdate Use client-side JS to update the display so components like "today" are always correct.
 * @returns HTML for the date display with a tooltip.
 */
export function formatDateFriendly(
  date: Date,
  timezone: string,
  options?: Parameters<typeof formatDateFriendlyString>[2] & { liveUpdate?: boolean },
): HtmlSafeString {
  return html`<span
    class="${options?.liveUpdate ? html`js-format-date-friendly-live-update` : ''}"
    data-format-date="${options?.liveUpdate
      ? JSON.stringify({ date: date.toISOString(), timezone, options })
      : ''}"
    data-bs-toggle="tooltip"
    data-bs-title="${formatDate(date, timezone, { includeTz: true, longTz: true })}"
    >${formatDateFriendlyString(date, timezone, options)}</span
  >`;
}

/**
 * Format a datetime range to a string like:
 * - 'today, 10am'
 * - 'today, 3pm to 5pm'
 * - 'today, 3pm to tomorrow, 5pm'
 * - 'today, 3pm to 5pm (CDT)' (if `includeTz` is true)
 * - '3pm today to 5pm tomorrow' (if `timeFirst` is true)
 * - 'today to tomorrow' (if `dateOnly` is true)
 *
 * This uses `formatDateFriendlyString()` to format the individual dates and times.
 *
 * @param start The start date and time.
 * @param end The end date and time.
 * @param timezone The time zone to use for formatting.
 * @param options Additional options for formatting the displayed date, taken from `formatDateFriendlyString()`.
 * @returns Human-readable string representing the datetime range.
 */
export function formatDateRangeFriendlyString(
  start: Date,
  end: Date,
  timezone: string,
  {
    baseDate = new Date(),
    includeTz = true,
    timeFirst = false,
    dateOnly = false,
  }: Parameters<typeof formatDateFriendlyString>[2] = {},
): string {
  const {
    dateFormatted: startDateFormatted,
    timeFormatted: startTimeFormatted,
    timezoneFormatted,
  } = formatDateFriendlyParts(start, timezone, baseDate);
  const { dateFormatted: endDateFormatted, timeFormatted: endTimeFormatted } =
    formatDateFriendlyParts(end, timezone, baseDate);

  let result: string | undefined;
  if (dateOnly) {
    if (startDateFormatted == endDateFormatted) {
      result = startDateFormatted;
    } else {
      result = `${startDateFormatted} to ${endDateFormatted}`;
    }
  } else {
    if (startDateFormatted == endDateFormatted) {
      let timeRangeFormatted: string | undefined;
      if (startTimeFormatted == endTimeFormatted) {
        timeRangeFormatted = startTimeFormatted;
      } else {
        timeRangeFormatted = `${startTimeFormatted} to ${endTimeFormatted}`;
      }
      if (timeFirst) {
        result = `${timeRangeFormatted} ${startDateFormatted}`;
      } else {
        result = `${startDateFormatted}, ${timeRangeFormatted}`;
      }
    } else {
      if (timeFirst) {
        result = `${startTimeFormatted} ${startDateFormatted} to ${endTimeFormatted} ${endDateFormatted}`;
      } else {
        result = `${startDateFormatted}, ${startTimeFormatted} to ${endDateFormatted}, ${endTimeFormatted}`;
      }
    }
  }
  if (includeTz) {
    result = `${result} ${timezoneFormatted}`;
  }
  return result;
}

/**
 * Format a datetime range to HTML, displaying the date with
 * `formatDateRangeFriendlyString()` and with a tooltip showing the exact dates
 * and times.
 *
 * @param start The start date and time.
 * @param end The end date and time.
 * @param timezone The time zone to use for formatting.
 * @param options Additional options for formatting the displayed date, taken from `formatDateFriendlyString()`.
 * @param options.liveUpdate Use client-side JS to update the display so components like "today" are always correct.
 * @returns HTML for the datetime range with a tooltip.
 */
export function formatDateRangeFriendly(
  start: Date,
  end: Date,
  timezone: string,
  options?: Parameters<typeof formatDateFriendlyString>[2] & { liveUpdate?: boolean },
): HtmlSafeString {
  return html`<span
    class="${options?.liveUpdate ? html`js-format-date-range-friendly-live-update` : ''}"
    data-format-date-range="${options?.liveUpdate
      ? JSON.stringify({
          start: start.toISOString(),
          end: end.toISOString(),
          timezone,
          options,
        })
      : ''}"
    data-bs-toggle="tooltip"
    data-bs-title="${formatDate(start, timezone, { includeTz: false })} — ${formatDate(
      end,
      timezone,
      { includeTz: true, longTz: true },
    )}"
    >${formatDateRangeFriendlyString(start, end, timezone, options)}</span
  >`;
}

// Some functions were initially implemented locally in PrairieTest before being
// moved into the `@prairielearn/formatter` package published from the main
// PrairieLearn repo. To avoid huge changes in PrairieTest, we'll re-export them.
//
// Someday, when all the functions from this file are moved to the formatter
// package, we can update all PrairieTest code to import them directly.
export { formatDate, formatDateYMD, formatDateYMDHM, formatInterval };
